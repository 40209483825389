import React, { useEffect, useState } from "react";
import { Card, List, ListItem, Title, Text, Bold, Flex } from "@tremor/react";
import { UserIcon } from "@heroicons/react/solid";
import api from '../../../services/api';

const getRandomColor = () => {
    const colors = [
      "bg-red-500", "bg-blue-500", "bg-green-500", "bg-yellow-500", 
      "bg-blue-500", "bg-purple-500", "bg-pink-500"
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

const TopMerchants = () => {
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    const fetchTopMerchants = async () => {
      try {
        const response = await api.get('/business/top');
        setMerchants(response.data);
      } catch (error) {
        console.error('Error fetching top merchants:', error);
      }
    };

    fetchTopMerchants();
  }, []);

  return (
    <Card className="max-w-full">
      <Title>High Performing Merchants</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Business</Bold>
        </Text>
        <Text>
          <Bold>Orders Recieved</Bold>
        </Text>
      </Flex>
      <List>
        {merchants.map((merchant) => (
          <ListItem key={merchant.merchant_id} className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={`w-6 h-6 rounded-full ${getRandomColor()} mr-2 flex justify-center items-center`}>
              <UserIcon width={12} className="text-white" />
            </div>
            <Text>{merchant.business_name}</Text>
          </div>
          <Text>{merchant.order_count}</Text>
        </ListItem>
      ))}
    </List>
  </Card>
);
};

export default TopMerchants;