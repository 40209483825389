import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';

const BusinessVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [verificationUrl, setVerificationUrl] = useState(null);

  const initiateVerification = async (reference, email) => {
    try {
      console.log('Initiating verification for:', reference, email);
      const response = await api.post('/verify/initiate-verification', { reference, email });

      if (response.data.status === 'ongoing') {
        setVerificationStatus('ongoing');
        setVerificationUrl(response.data.verification_url);
      } else {
        console.error('Unexpected response:', response.data);
        setVerificationStatus('error');
        toast.error('Failed to initiate verification');
      }
    } catch (error) {
      console.error('Error initiating verification:', error);
      setVerificationStatus('error');
      toast.error(error.response?.data?.message || 'Error initiating verification');
    }
  };

  useEffect(() => {
    const state = location.state || {};
    const { reference, email } = state;
    
    if (!reference || !email) {
      console.error('Missing required data:', { reference, email });
      setVerificationStatus('error');
      toast.error('Missing required verification data');
      return;
    }

    initiateVerification(reference, email);

    const handleMessage = async (event) => {
      // Verify origin
      if (event.origin !== 'https://app.shuftipro.com') {
        console.warn('Received message from unexpected origin:', event.origin);
        return;
      }

      console.log('Received postMessage:', event.data);

      const { reference: callbackReference, verification_status } = event.data;

      if (!verification_status) {
        console.warn('No verification status in message:', event.data);
        return;
      }

      try {
        // Instead of updating status separately, let Shufti Pro's callback handle it
        // Just update the UI based on the received status
        switch (verification_status) {
          case 'verification.accepted':
            setVerificationStatus('verified');
            toast.success('Verification successful!');
            // Redirect to login after a short delay
            setTimeout(() => {
              navigate('/login', { 
                state: { message: 'Registration completed successfully. Please login.' }
              });
            }, 2000);
            break;
            
          case 'verification.declined':
            setVerificationStatus('failed');
            toast.error('Verification declined. Please try again.');
            break;
            
          case 'verification.cancelled':
            setVerificationStatus('cancelled');
            toast.info('Verification cancelled.');
            break;
            
          case 'request.timeout':
            setVerificationStatus('timeout');
            toast.error('Verification timed out. Please try again.');
            break;
            
          case 'request.received':
          case 'request.pending':
            // These are intermediate states, don't need to show toasts
            setVerificationStatus('ongoing');
            break;
            
          default:
            console.warn('Unhandled verification status:', verification_status);
        }
      } catch (error) {
        console.error('Error handling verification status:', error);
        toast.error('Error processing verification result');
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [location.state, navigate]);

  const renderVerificationIframe = () => {
    if (verificationStatus === 'ongoing' && verificationUrl) {
      return (
        <iframe
          src={verificationUrl}
          title="Shufti Pro Verification"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            zIndex: 9999,
          }}
          allow="camera *; microphone *"
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-downloads"
        />
      );
    }
    return null;
  };

  return (
    <div className="relative">
      <div className="absolute top-[300px] left-[-336px] w-[2351.9px] h-[968.7px] flex flex-row items-start justify-start">
        <img
          className="h-[968.7px] flex-1 relative max-w-full overflow-hidden object-contain"
          alt=""
          src="/background-waves@2x.png"
        />
      </div>
      <div className="relative z-10">
        <p className="mt-20 text-lg p-10">
          I hereby acknowledge that the provided information is accurate and true. <br />
          I also consent to this information to be used by 3 Shepherd for background, criminality (KYC) and international sanctions <br />
          checks in order to verify honest and trustworthy business in accordance with UAE and international regulations. <br /><br />
          <strong>You will be redirected to login after being successfully verified.</strong>
        </p>
        
        {verificationStatus === 'pending' && (
          <p className="mt-5 text-lg p-10">Loading verification process...</p>
        )}
        
        {verificationStatus === 'ongoing' && verificationUrl && (
          <div className="fixed inset-0 z-50 bg-white">
            {renderVerificationIframe()}
          </div>
        )}
        
        {['failed', 'cancelled', 'timeout', 'error'].includes(verificationStatus) && (
          <div className="mt-5 p-10">
            <p className="text-lg text-red-500 mb-4">
              {verificationStatus === 'failed' && 'Verification was declined. Please try again.'}
              {verificationStatus === 'cancelled' && 'Verification was cancelled. Please try again when ready.'}
              {verificationStatus === 'timeout' && 'Verification timed out. Please try again.'}
              {verificationStatus === 'error' && 'Error during verification. Please try again.'}
            </p>
            <button
              onClick={() => window.location.reload()}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Retry Verification
            </button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default BusinessVerification;