import React, { useState } from 'react';
import { Bell, DollarSign, Package, AlertCircle } from 'lucide-react';

const MerchantNotifications = () => {
  // Default notifications data
  const [notifications] = useState([
    {
      type: 'ORDER',
      orderId: '56039',
      status: 'NEW',
      timeAgo: '2 minutes ago',
    },
    {
      type: 'TRANSACTION',
      amount: '87.59',
      currency: 'AED',
      status: 'COMPLETED',
      timeAgo: 'an hour ago',
    },
    {
      type: 'ORDER',
      orderId: '56040',
      status: 'UNDER_REVIEW',
      timeAgo: '3 hours ago',
    }
  ]);

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'ORDER':
        return <Package className="w-6 h-6 text-blue-500" />;
      case 'TRANSACTION':
        return <DollarSign className="w-6 h-6 text-green-500" />;
      default:
        return <Bell className="w-6 h-6 text-gray-500" />;
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toUpperCase()) {
      case 'NEW':
        return 'bg-green-100 text-green-800';
      case 'UNDER_REVIEW':
        return 'bg-yellow-100 text-yellow-800';
      case 'COMPLETED':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatNotificationMessage = (notification) => {
    switch (notification.type) {
      case 'ORDER':
        return `New order #${notification.orderId} received`;
      case 'TRANSACTION':
        return `Payment of ${notification.currency} ${notification.amount} received`;
      default:
        return notification.message;
    }
  };

  const hasNotifications = notifications.length > 0;

  return (
    <div className="w-full max-w-md bg-white rounded-lg shadow">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Latest Activities</h2>
          {hasNotifications && (
            <span className="px-2 py-1 text-xs font-medium text-blue-600 bg-blue-100 rounded-full">
              {notifications.length} new
            </span>
          )}
        </div>
      </div>

      {!hasNotifications ? (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <AlertCircle className="w-12 h-12 text-gray-400 mb-3" />
          <p className="text-gray-500">No new notifications</p>
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {notifications.map((notification, index) => (
            <li
              key={index}
              className="p-4 hover:bg-gray-50 transition-colors duration-150 ease-in-out"
            >
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  {getNotificationIcon(notification.type)}
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900">
                    {formatNotificationMessage(notification)}
                  </p>
                  <div className="mt-1 flex items-center space-x-2">
                    <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getStatusBadgeColor(notification.status)}`}>
                      {notification.status}
                    </span>
                    <span className="text-xs text-gray-500">
                      {notification.timeAgo}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MerchantNotifications;