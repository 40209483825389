import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useState, useEffect } from "react";
import api from '../../services/api';
const Widget = ({ type }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const diff = 20; // Temporary

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          let response;
          if (type === "order") {
            response = await api.get(`/users/${userId}/orders/count`);
            setAmount(response.data.totalOrders);
          } else if (type === "pending") {
            response = await api.get(`/users/${userId}/orders/pending`);
            setAmount(response.data.length);
          }else if (type === "past") {
            response = await api.get(`/users/${userId}/orders/past`);
            setAmount(response.data.length);
          }else if (type === "earning") {
            response = await api.get(`/users/${userId}/orders/total-spent`);
            setAmount(response.data.totalSpent);
          }
        } catch (error) {
          console.error(`Error fetching ${type} data:`, error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [type, userId]);
  let data;

  switch (type) {
    case "pending":
      data = {
        title: "PENDING ORDERS",
        isMoney: false,
        link: "See all orders pending delivery",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "TOTAL ORDERS",
        isMoney: false,
        link: "View all orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "TOTAL SPENT",
        isMoney: true,
        link: "View net spending",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "past":
      data = {
        title: "PAST ORDERS",
        isMoney: false,
        link: "View order history",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
  {loading ? "Loading..." : `${data.isMoney ? "AED " : ""}${amount}`}
</span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
