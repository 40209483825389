import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GoogleLogin } from '@react-oauth/google';
import api from '../../services/api';

function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await api.post('/auth/login', 
        { email, password }, 
        { withCredentials: true }
      );

      if (response.data?.user) {
        const user = response.data.user;
        
        // Store user data in localStorage
        localStorage.setItem('user', JSON.stringify(user));

        // Handle navigation based on role
        switch(user.role) {
          case 'owner':
          case 'merchant':
            navigate('/panel', { replace: true });
            break;
          case 'user':
            navigate('/userpanel', { replace: true });
            break;
          default:
            console.error('Unknown user role:', user.role);
            setError('Invalid user role');
            localStorage.removeItem('user');
        }
      } else {
        throw new Error('Invalid response: No user data received');
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // Clear any existing user data
      localStorage.removeItem('user');

      // Handle different error scenarios
      if (error.response) {
        const status = error.response.status;
        const errorMessage = error.response.data?.message;

        switch (status) {
          case 401:
            setError('Invalid email or password');
            break;
          case 403:
            setError('Account access denied. Please contact support.');
            break;
          case 429:
            setError('Too many login attempts. Please try again later.');
            break;
          case 500:
            setError('Server error. Please try again later.');
            break;
          default:
            setError(errorMessage || 'Login failed. Please try again.');
        }
      } else if (error.request) {
        setError('Network error. Please check your internet connection.');
      } else {
        setError('Login failed. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await api.post('/auth/google', {
        credential: response.credential,
      }, { withCredentials: true });

      if (res.data.success) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        navigate('/userpanel', { replace: true });
      }
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google sign-in failed:', error);
  };

  

  // Handle UAE Pass login
  const handleUaePassSignIn = () => {
    window.location.href = 'https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/auth/uaepass'; // Update this URL to point to your backend UAE Pass auth route
  };
  

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: '#08C989' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
          />
          <Typography variant="body" sx={{ mt: 2, mb: 1, alignContent: 'center' }}>
            ----------- Or continue with email -----------
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <button onClick={handleUaePassSignIn} className="uae-pass-button">
        <img src="/UAEPASS_Sign_in.svg" alt="UAE Pass Login" alignItems = "centre" /> {/* Path to the SVG in public folder */}
      </button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

function UaePassCallbackHandler() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleUaePassCallback = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const email = queryParams.get('email');
      const name = queryParams.get('name');
      const role = queryParams.get('role');

      if (!token || !email ) {
        console.error('Token or user info is missing');
        navigate('/login', { replace: true });
        return;
      }

      try {
        // Store JWT and user data in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify({ email, name, role }));

        // Redirect to the user panel after successful login
        navigate('/userpanel', { replace: true });
      } catch (error) {
        console.error('Error handling UAE Pass callback:', error);
        navigate('/login', { replace: true });
      }
    };

    handleUaePassCallback();
  }, [location, navigate]);

  return <div>Logging you in...</div>;
}
export { SignIn, UaePassCallbackHandler };