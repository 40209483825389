import React, { useState } from 'react';
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Avatar,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import LicenseVerification from './LicenseVerification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function OTPDialog({ open, email, onClose, onVerify }) {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleVerify = async () => {
    try {
      const response = await api.post('/auth/verify-otp', {
        email,
        otp
      });

      if (response.data.success) {
        onVerify();
      } else {
        setError('Invalid OTP code');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Verification failed. Please try again.');
    }
  };

  const handleResendOTP = async () => {
    try {
      setIsResending(true);
      await api.post('/auth/send-otp', { email });
      setCountdown(60);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      setError('Failed to resend OTP');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Verify your email</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Enter the verification code sent to {email}
        </Typography>
        <TextField
          autoFocus
          fullWidth
          label="Verification Code"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          error={!!error}
          helperText={error}
          margin="normal"
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, flexDirection: 'column', gap: 1 }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button 
            onClick={handleResendOTP} 
            disabled={isResending || countdown > 0}
            color="secondary"
          >
            {countdown > 0 ? `Resend in ${countdown}s` : 'Resend Code'}
          </Button>
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
            <Button onClick={handleVerify} variant="contained">
              Verify
            </Button>
          </Box>
        </Box>
        {error && (
          <Typography color="error" variant="caption" sx={{ width: '100%', textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </DialogActions>
    </Dialog>
  );
}

const businessSteps = ['Account Setup', 'License Verification', 'Identity Verification'];

export default function Registration() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  // Individual registration form
  const [individualForm, setIndividualForm] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: ''
  });

  // Business registration form
  const [businessForm, setBusinessForm] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    businessName: '',
    businessAddress: '',
    licenseNumber: '',
    licenseFileUrl: '',
    licenseIssueDate: '',
    licenseExpiryDate: ''
  });

  const [pendingForm, setPendingForm] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setActiveStep(0);
    setError('');
  };

  const handleIndividualChange = (e) => {
    setIndividualForm({ ...individualForm, [e.target.name]: e.target.value });
  };

  const handleBusinessChange = (e) => {
    setBusinessForm({ ...businessForm, [e.target.name]: e.target.value });
  };

  const handleIndividualSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await api.post('/auth/send-otp', { email: individualForm.email });
      setPendingForm({
        type: 'individual',
        data: {
          username: individualForm.username,
          password: individualForm.password,
          name: `${individualForm.firstName} ${individualForm.lastName}`,
          email: individualForm.email,
          role: 'user',
          userType: 'individual'
        }
      });
      setOtpDialogOpen(true);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to send verification code');
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessNext = async () => {
    try {
      setLoading(true);
      setError('');
  
      if (activeStep === 0) {
        await api.post('/auth/send-otp', { email: businessForm.email });
        setPendingForm({
          type: 'business',
          data: {
            username: businessForm.username,
            password: businessForm.password,
            name: `${businessForm.firstName} ${businessForm.lastName}`,
            email: businessForm.email
          }
        });
        setOtpDialogOpen(true);
      } else if (activeStep === 1) {
        const response = await api.post('/auth/business-register', businessForm);
        navigate('/business-verification', {
          state: {
            reference: response.data.reference,
            email: businessForm.email
          }
        });
        return;
      }
  
      setActiveStep((prev) => prev + 1);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };
  
  const handleVerificationSuccess = async () => {
    try {
      if (pendingForm.type === 'individual') {
        await api.post('/auth/register', pendingForm.data);
        navigate('/login');
      } else if (pendingForm.type === 'business') {
        setActiveStep(1);
        setOtpDialogOpen(false);
        setPendingForm(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#08C989' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          Sign up
        </Typography>
        
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            centered
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: 2
            }}
          >
            <Tab label="Individual" />
            <Tab label="Business" />
          </Tabs>

          {/* Individual Registration */}
          <TabPanel value={tabValue} index={0}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <form onSubmit={handleIndividualSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      required
                      fullWidth
                      size="small"
                      value={individualForm.firstName}
                      onChange={handleIndividualChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      required
                      fullWidth
                      size="small"
                      value={individualForm.lastName}
                      onChange={handleIndividualChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="username"
                      label="Username"
                      required
                      fullWidth
                      size="small"
                      value={individualForm.username}
                      onChange={handleIndividualChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      required
                      fullWidth
                      size="small"
                      type="email"
                      value={individualForm.email}
                      onChange={handleIndividualChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      label="Password"
                      required
                      fullWidth
                      size="small"
                      type="password"
                      value={individualForm.password}
                      onChange={handleIndividualChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      sx={{ 
                        bgcolor: '#08C989', 
                        '&:hover': { bgcolor: '#07b37b' },
                        mt: 1 
                      }}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Sign Up'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </TabPanel>

          {/* Business Registration */}
          <TabPanel value={tabValue} index={1}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
                {businessSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              {activeStep === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      required
                      fullWidth
                      size="small"
                      value={businessForm.firstName}
                      onChange={handleBusinessChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      required
                      fullWidth
                      size="small"
                      value={businessForm.lastName}
                      onChange={handleBusinessChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="username"
                      label="Username"
                      required
                      fullWidth
                      size="small"
                      value={businessForm.username}
                      onChange={handleBusinessChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      required
                      fullWidth
                      size="small"
                      type="email"
                      value={businessForm.email}
                      onChange={handleBusinessChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      label="Password"
                      required
                      fullWidth
                      size="small"
                      type="password"
                      value={businessForm.password}
                      onChange={handleBusinessChange}
                    />
                  </Grid>
                </Grid>
              )}

              {activeStep === 1 && (
                <LicenseVerification
                  onNext={handleBusinessNext}
                  setBusinessForm={setBusinessForm}
                  businessForm={businessForm}
                  reference={pendingForm?.data?.reference}
                />
              )}

              {activeStep === 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                    variant="contained"
                    onClick={handleBusinessNext}
                    disabled={loading}
                    sx={{
                      bgcolor: '#08C989',
                      '&:hover': { bgcolor: '#07b37b' },
                    }}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Next'}
                  </Button>
                </Box>
              )}
            </Paper>
          </TabPanel>
        </Box>

        <OTPDialog
          open={otpDialogOpen}
          email={pendingForm?.data?.email || ''}
          onClose={() => {
            setOtpDialogOpen(false);
            setPendingForm(null);
          }}
          onVerify={handleVerificationSuccess}
        />
      </Box>
    </Container>
  );
}