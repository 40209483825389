import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

// Create context
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to check the current user's session
    const checkSession = async () => {
        setIsLoading(true); // Ensure loading state is true when session check starts
        try {
            const response = await api.get('/auth/session', { withCredentials: true });
            if (response.status === 200 && response.data.user) {
                setUser(response.data.user);  // Set user data on successful session check
            } else {
                throw new Error('No active session'); // Handle case where session is not active
            }
        } catch (error) {
            setError(error.message || 'Failed to fetch session');
            setUser(null);  // Clear user data if session check fails
        } finally {
            setIsLoading(false);  // Ensure loading state is false after session check
        }
    };

    // Login function to authenticate user
    const login = async (email, password) => {
        setIsLoading(true);
        try {
            const response = await api.post('/auth/login', { email, password }, { withCredentials: true });
            if (response.data && response.data.user) {
                setUser(response.data.user);
                setError(null);
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Login error');
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    };

    // Logout function to terminate the session
    const logout = async () => {
        setIsLoading(true);
        try {
            await api.post('/auth/logout', { withCredentials: true });
            setUser(null);
        } catch (error) {
            setError('Logout failed');
        } finally {
            setIsLoading(false);
        }
    };

    // Check session on provider mount
    useEffect(() => {
        checkSession();
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout, isLoading, error }}>
            {children}
        </AuthContext.Provider>
    );
};
