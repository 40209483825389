import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./panel.scss";
import Widget from "../../components/widget/MerchantWidget";
import MerchantNotifications from "../../components/notifications/Notifications";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/ShipmentTable";

const notifications = [
  {
    type: 'ORDER',
    orderId: '56039',
    status: 'NEW',
    timeAgo: '2 minutes ago',
  },
  {
    type: 'TRANSACTION',
    amount: '87.59',
    currency: 'AED',
    status: 'COMPLETED',
    timeAgo: 'an hour ago',
  }
];


const Panel = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="pending" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="wallet" />
        </div>
        <div className="charts">
        <MerchantNotifications notifications={notifications} />  {/* Replace Featured component with Notifications */}
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div>
        <div className="listContainer">
          <div className="listTitle">Open Shipments</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Panel;
