import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useState, useEffect } from "react";
import api from '../../services/api';

const MerchantWidget = ({ type }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const diff = 20; // Temporary

  const user = JSON.parse(localStorage.getItem("user"));
  const merchantId = user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        switch (type) {
          case "pending":
            response = await api.get(`/business/${merchantId}/orders/pending/count`);
            setAmount(response.data.count || 0);
            break;
          case "order":
            response = await api.get(`/business/${merchantId}/orders/delivered`);
            setAmount(response.data.count);
            break;
          case "earning":
            response = await api.get(`/business/${merchantId}/orders/delivered/total-amount`);
            setAmount(response.data.totalAmount || 0);
            break;
          case "wallet":
            response = await api.get(`/business/${merchantId}/orders/not-delivered/total-amount`);
            setAmount(response.data.totalAmount || 0);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(`Error fetching data for ${type}:`, error);
        setAmount(0); // Set to 0 in case of error
      } finally {
        setLoading(false);
      }
    };

    if (merchantId) {
      fetchData();
    }
  }, [type, merchantId]);
  let data;

  switch (type) {
    case "pending":
      data = {
        title: "Open Shipments",
        isMoney: false,
        link: "See all orders pending delivery",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "COMPLETED ORDERS",
        isMoney: false,
        link: "View all orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "TOTAL EARNINGS",
        isMoney: true,
        link: "View net spending",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "wallet":
      data = {
        title: "FUNDS ON HOLD",
        isMoney: true,
        link: "View order history",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {loading ? "Loading..." : `${data.isMoney ? "AED " : ""}${amount}`}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default MerchantWidget;
