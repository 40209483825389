import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Flex,
  Dialog,
  DialogPanel,
  Select,
  SelectItem,
  TextInput,
} from "@tremor/react"; // or "@mui/material"
import api from '../../services/api';

const PickupRequestList = () => {
  const [pickupRequests, setPickupRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRequestDetails, setSelectedRequestDetails] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const merchantId = user?.id;

  useEffect(() => {
    const fetchPickupRequests = async () => {
      try {
        const response = await api.get(`/pickuprequests/getlist/${merchantId}`);
        setPickupRequests(response.data);
        setFilteredRequests(response.data);
      } catch (error) {
        console.error('Error fetching pickup requests:', error);
      }
    };

    if (merchantId) {
      fetchPickupRequests();
    }
  }, [merchantId]);

  const handleStatusFilterChange = (e) => {
    const value = e.target.value;
    setStatusFilter(value);
    filterRequests(value, searchTerm);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterRequests(statusFilter, value);
  };

  const filterRequests = (status, search) => {
    let filtered = pickupRequests;
    if (status) {
      filtered = filtered.filter(request => request.status === status);
    }
    if (search) {
      filtered = filtered.filter(request =>
        request.transaction_id.includes(search) ||
        request.confirmation_code.includes(search)
      );
    }
    setFilteredRequests(filtered);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "emerald";
      case "Created":
        return "blue";
      case "Cancelled":
        return "red";
      default:
        return "gray";
    }
  };

  const handleCancelPickupRequest = async (pickupRequest) => {
    try {
      const scheduledDateOnly = pickupRequest.scheduled_date.split('T')[0];
      const payload = {
        merchantId,
        confirmationCode: pickupRequest.confirmation_code,  // Use the confirmation code from the row
        carrier_code: pickupRequest.carrier_code,           // Use carrier code from the row
        scheduledDate: scheduledDateOnly,                   // Use only the date part
    };

      const response = await api.post('/fedex/cancelrequest', payload);

      if (response.data.success) {
        // Refresh the requests after successful cancellation
        const updatedResponse = await api.get(`/pickuprequests/getlist/${merchantId}`);
        setPickupRequests(updatedResponse.data);
        setFilteredRequests(updatedResponse.data);
      } else {
        // Handle failure - display the error message
        alert(`Failed to cancel pickup request: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error canceling pickup request:', error.message);
      alert('An error occurred while canceling the pickup request.');
    }
  };

  const handleCompleteRequest = async (requestId) => {
    try {
      await api.post(`/pickuprequests/${requestId}/complete`);
      // Refresh the requests after completion
      const response = await api.get(`/pickuprequests/getlist/${merchantId}`);
      setPickupRequests(response.data);
      setFilteredRequests(response.data);
    } catch (error) {
      console.error('Error completing request:', error);
    }
  };


  return (
    <Card className="mt-4 mb-4">
      <Title>Pickup Requests for {user.name}</Title>
      <Flex className="mt-4 mb-4">
        <Select onChange={handleStatusFilterChange} value={statusFilter} placeholder="Filter by Status">
          <SelectItem value="">All</SelectItem>
          <SelectItem value="completed">Completed</SelectItem>
          <SelectItem value="pending">Pending</SelectItem>
          <SelectItem value="cancelled">Cancelled</SelectItem>
        </Select>
        <TextInput
          placeholder="Search by Transaction ID or Confirmation Code"
          onChange={handleSearchChange}
          value={searchTerm}
          className="ml-2"
        />
      </Flex>
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Transaction ID</TableHeaderCell>
            <TableHeaderCell>Confirmation Code</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Scheduled Date</TableHeaderCell>
            <TableHeaderCell>Carrier Code</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRequests.map((request) => (
            <TableRow key={request.transaction_id}>
              <TableCell>{request.transaction_id}</TableCell>
              <TableCell>
                <Text>{request.confirmation_code}</Text>
              </TableCell>
              <TableCell>
                <Badge color={getStatusColor(request.status)}>
                  {request.status}
                </Badge>
              </TableCell>
              <TableCell>
                <Text>{new Date(request.scheduled_date).toLocaleString()}</Text>
              </TableCell>
              <TableCell>
                <Text>{request.carrier_code}</Text>
              </TableCell>
              
              <TableCell>
                <Flex justifyContent="between">
                  <Button variant='secondary' color="red" onClick={() => handleCancelPickupRequest(request)} disabled={request.status === "cancelled"}>
                    Cancel Request
                  </Button>
                  <Button variant = 'secondary' color="blue" onClick={() => handleCompleteRequest(request.transaction_id)} disabled={request.status === "cancelled" || request.status === "completed"}>
                    Request Completed
                  </Button>
                </Flex>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
    </Card>
  );
};

export default PickupRequestList;