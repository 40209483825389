import "./chart.scss";
import { useState, useEffect } from "react";
import api from '../../services/api';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ aspect, title }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));
  const merchantId = user?.id;

  useEffect(() => {
    const fetchMonthlyOrders = async () => {
      try {
        const response = await api.get(`/business/monthly-orders/${merchantId}`); // Adjust the endpoint as necessary
        setData(response.data.formattedResult);
      } catch (error) {
        console.error('Error fetching monthly orders:', error);
      } finally {
        setLoading(false);
      }
    };

    if (merchantId) {
      fetchMonthlyOrders();
    }
  }, [merchantId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <BarChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <XAxis dataKey="name" stroke="gray" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Orders" fill="#7CB3EE" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
