import UserSidebar from "../../components/sidebar/UserSidebar";
import Navbar from "../../components/navbar/Navbar";
import "./panel.scss";
import Widget from "../../components/widget/Widget";
//import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";


const UserPanel = () => {
  return (
    <div className="home">
      <UserSidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="pending" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="past" />
        </div>
        
        <div className="listContainer">
          <div className="listTitle">Active Orders</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default UserPanel;