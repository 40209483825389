import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './components/AuthProvider'; // Adjust the path as necessary
import AdminPanel from "./pages/panel/AdminPanel";
import Panel from "./pages/panel/Panel";
import UserPanel from "./pages/panel/UserPanel";
import SignUp from './components/Auth/SignUp';
import BusinessRegister from './components/Auth/BusinessRegister';
import BusinessVerification from './components/Auth/BusinessVerification';
import {SignIn, UaePassCallbackHandler } from './components/Auth/SignIn';
import Home from './pages/Home';
import Admin from './pages/Admin';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/Contact/ContactUs';
import CompleteProfileForm from './pages/Merchants/CompleteProfileForm';
import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation';
import './global.css';
import Tracking from './components/FedEx/tracking';
import CreateShipmentForm from './components/FedEx/CreateShipment';
import Checkout from './components/checkout/Checkout';
import AdminUserList from './pages/panel/admincomps/AdminUserList';
import OrdersTable from './components/Merchant/OrdersTable';
import RequestPickup from './components/Merchant/RequestPickup';
import MerchantSettings from './components/Merchant/Settings';
import BlockExpressCheckoutWal from './pages/button/BlockExpressCheckoutWal';


const CLIENT_ID = '29330502523-qftoqtsjmtenl9kpqn36985aq2fugduk.apps.googleusercontent.com';

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AuthProvider>  {/* Wrap everything inside AuthProvider */}
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/auth/uaepass/callback" element={<UaePassCallbackHandler />} />
            <Route path="/button" element={<BlockExpressCheckoutWal />} />
            <Route path="/adminpanel" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
            <Route path="/panel" element={<PrivateRoute><Panel /></PrivateRoute>} />
            <Route path="/userpanel" element={<PrivateRoute><UserPanel /></PrivateRoute>} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/business-register" element={<BusinessRegister />} />
            <Route path="/business-verification" element={<BusinessVerification />} />
            <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
            <Route path="/adminuserlist" element={<PrivateRoute><AdminUserList /></PrivateRoute>} />
            <Route path="/tracking/:trackingNumber/:orderId" element={<PrivateRoute><Tracking /></PrivateRoute>} />
            <Route path="/tracking" element={<PrivateRoute><Tracking /></PrivateRoute>} />
            <Route path="/merchant-orders" element={<PrivateRoute><OrdersTable /></PrivateRoute>} />
            <Route path="/requestpickup" element={<PrivateRoute><RequestPickup /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><MerchantSettings /></PrivateRoute>} />
            <Route path="/createshipment" element={<PrivateRoute><CreateShipmentForm /></PrivateRoute>} />
            <Route path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
            <Route path="/complete-merchant-profile" element={<PrivateRoute><CompleteProfileForm /></PrivateRoute>} />
          </Routes>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
    </>
  );
};

export default App;
