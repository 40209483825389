import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const FormWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: 'auto',
  maxWidth: 600,
}));

const CompleteProfileForm = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      warehouse_address_line1: formData.get('warehouse_address_line1'),
      warehouse_address_line2: formData.get('warehouse_address_line2'),
      warehouse_city: formData.get('warehouse_city'),
      warehouse_state: formData.get('warehouse_state'),
      warehouse_zip_code: formData.get('warehouse_zip_code'),
      warehouse_country: formData.get('warehouse_country'),
      contact_phone: formData.get('contact_phone'),
      contact_email: formData.get('contact_email'),
      account_IBAN: formData.get('account_IBAN'),
      name: formData.get('name'), // New field: Merchant's name
      registered_business_name: formData.get('registered_business_name'), // New field: Registered business name
    };

    try {
      const response = await axios.post('/nbd/createVA', data); // Send data to backend endpoint
      console.log('Profile completed successfully:', response.data);
      // Display response details on the frontend if needed
    } catch (error) {
      console.error('Error completing profile:', error);
      // Handle error responses from backend or display error message to user
    }
  };

  return (
    <FormWrapper elevation={3}>
      <Typography variant="h6" gutterBottom>
        Complete your Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="registered_business_name"
              name="registered_business_name"
              label="Registered Business Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="warehouse_address_line1"
              name="warehouse_address_line1"
              label="Warehouse Address Line 1"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="warehouse_address_line2"
              name="warehouse_address_line2"
              label="Warehouse Address Line 2"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="warehouse_city"
              name="warehouse_city"
              label="Warehouse City"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="warehouse_state"
              name="warehouse_state"
              label="Warehouse State"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="warehouse_zip_code"
              name="warehouse_zip_code"
              label="Warehouse Zip Code"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="warehouse_country"
              name="warehouse_country"
              label="Warehouse Country"
              defaultValue="United Arab Emirates"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="contact_phone"
              name="contact_phone"
              label="Contact Phone"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="contact_email"
              name="contact_email"
              label="Contact Email"
              type="email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="account_IBAN"
              name="account_IBAN"
              label="Bank Account IBAN"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormWrapper>
  );
};

export default CompleteProfileForm;
