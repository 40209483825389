import React from "react";
import "./navbar.scss";
import { useAuth } from '../../components/AuthProvider'; // Adjust the path as necessary
import { toast } from 'react-toastify'; // Assuming you use react-toastify for notifications
import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Link, useNavigate } from 'react-router-dom';


const Navbar = () => {
  const { logout } = useAuth(); // This assumes logout is a method provided by your AuthProvider that clears the token
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = async () => {
    try {
        await logout();
        localStorage.clear();
        toast.info("You have been logged out."); // Inform the user
        navigate('/login');
    } catch (error) {
        console.error('Logout failed:', error);
        // Optionally inform the user that logout failed
    }
};


  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            {/* Display user's name if available */}
            {user && <Typography component="span">{user.name}</Typography>}

            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
